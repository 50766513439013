
export const reportInfoOption = {
  title: {
    text: '阿维塔项目',
    subtext: '测试用例执行结果',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  color: ['#74CB4A', '#F56C6C', '#E8A847'],
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 5, name: '通过' },
        { value: 2, name: '失败' },
        { value: 3, name: '跳过' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}
