<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/apiReport'}">测试报告</el-breadcrumb-item>
      <el-breadcrumb-item>测试报表信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 60%;padding-top: 10px;">
          <el-descriptions :column="2" size="medium" :labelStyle="{color: '#000000',fontWeight: 'bold'}">
            <el-descriptions-item label="报告名称">{{ reportInfoForm.report_name }}</el-descriptions-item>
            <el-descriptions-item label="开始时间">{{ reportInfoForm.created_time }}</el-descriptions-item>
            <el-descriptions-item label="报告状态">
              <el-tag effect="dark" :type="{'通过': 'success','未通过': 'danger','错误': 'warning'}[reportInfoForm.conclusion]">
                {{ reportInfoForm.conclusion }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="执行方式">{{ reportInfoForm.com_from }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="width: 40%;">
          <el-progress type="circle" :percentage="parseFloat(reportInfoForm.pass_rate * 100)" :width="70" style="left: 20px;"></el-progress>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="4">
          <div>
            <el-statistic :value="reportInfoForm.test_count" title="用例数">
              <template slot="prefix">
                <i class="el-icon-s-flag" style="color: red"></i>
              </template>
              <template slot="suffix">
                <i class="el-icon-s-flag" style="color: blue"></i>
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic title="通过用例数" :value="reportInfoForm.pass_count">
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="0" title="跳过用例数">
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="reportInfoForm.failed_count" title="失败用例数" suffix="">
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic title="通过率" :value="parseFloat(reportInfoForm.pass_rate * 100)" :precision="1" suffix="%">
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="parseFloat(reportInfoForm.total_time)" :precision="3" title="执行耗时" suffix="秒">
            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <div class="reportinfo-main">
      <el-card class="box-card box-card-info box-card-1">
        <div ref="caseTimeTop" style="width: auto;height:450px"></div>
      </el-card>
      <el-card class="box-card box-card-info box-card-2">
        <div ref="caseRunInfo" style="width: auto;height:450px"></div>
      </el-card>
    </div>

    <el-card box-card>
      <el-table
      :data="tableData"
      style="width: 100%"
      :tree-props="{ children: 'children' }" >
      <el-table-column
        prop="case_id"
        label="用例ID">
      </el-table-column>
      <el-table-column
        prop="case_name"
        label="用例名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="over_time"
        label="执行时长">
      </el-table-column>
      <el-table-column prop="test_result" label="结果">
        <template slot-scope="scope">
          <el-tag :type="{'通过': 'success','失败': 'danger','错误': 'warning'}[scope.row.test_result]">
            {{ scope.row.test_result }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" type="expand">
        <template slot-scope="scope">
          <el-descriptions title="用例信息">
            <template slot="extra">
              <el-button type="primary" size="mini" @click="queryCaseRequestInfo(scope.row)">查看详情</el-button>
            </template>
            <el-descriptions-item label="url">{{ scope.row.url }}</el-descriptions-item>
          </el-descriptions>
          <!-- <span>{{ scope.row }}</span> -->
          <!-- <el-button round type="success" @click="handleEdit(scope.row)">展开</el-button> -->
          <!-- <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    </el-card>

    <el-drawer
      title="用例执行信息"
      :visible.sync="drawerCaseRequestInfo"
      direction="rtl"
      size="40%">
      <el-card style="height: 100%;">
        <el-tabs v-model="activeNameTabs">
          <el-tab-pane label="请求数据" name="first">
            <el-tag effect="dark" size="mini">{{ caseRequestInfo.method }}</el-tag>
            <span style="margin-left: 10px;">{{ caseRequestInfo.url }}</span>
            <el-collapse style="margin-top: 10px;">
              <el-collapse-item title="Header" name="1">
                <div>{{ caseRequestInfo.headers }}</div>
              </el-collapse-item>
              <el-collapse-item title="Params" name="2">
                <div>{{ caseRequestInfo.params }}</div>
              </el-collapse-item>
              <el-collapse-item title="Body" name="3">
                <template slot="title">
                  <span>Body</span><el-tag size="mini" style="margin-left: 10px;">{{ caseRequestInfo.body.bodyType }}</el-tag>
                </template>
                <div>{{ caseRequestInfo.body.bodyContent }}</div>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="响应数据" name="second">
            <el-tag effect="dark" size="mini">{{ caseRequestInfo.status_code }}</el-tag>
            <el-tag style="margin-left: 10px;">响应时间：{{ caseRequestInfo.over_time }} ms</el-tag>
            <el-collapse style="margin-top: 10px;">
              <el-collapse-item title="Header" name="1">
                <div>{{ caseRequestInfo.response_headers }}</div>
              </el-collapse-item>
              <el-collapse-item title="Body" name="2">
                {{ caseRequestInfo.response_data }}
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="用例变量" name="third">
            <el-tag >前置用例</el-tag><span style="font-size: 13px;margin-left: 5px;">{{ caseRequestInfo.pre_data }}</span>
            <el-collapse style="margin-top: 10px;">
              <el-collapse-item title="用例提取变量" name="1">
                <div>{{ caseRequestInfo.case_var.pre_request_data }}</div>
              </el-collapse-item>
              <el-collapse-item title="环境变量" name="2">
                {{ caseRequestInfo.case_var.mate_env_var_list }}
              </el-collapse-item>
            </el-collapse>
            <!-- <span>post_operation:{{ caseRequestInfo.post_operation }}</span> -->
          </el-tab-pane>
          <el-tab-pane label="断言结果" name="fourth">
            <div>{{ caseRequestInfo.assert_result }}</div>
          </el-tab-pane>
          <el-tab-pane label="错误信息" name="five">
            {{ caseRequestInfo.status_code === '500' ? caseRequestInfo.response_data : '' }}
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-drawer>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import { reportInfoOption } from '@/services/reportInfo.js'
export default {
  name: 'ReportInfo',
  data () {
    return {
      reportInfoForm: {},
      tableData: this.$store.state.reportInfo.case_data,
      activeNameTabs: 'second',
      drawerCaseRequestInfo: false,
      caseRequestInfo: {
        case_var: '',
        body: ''
      },
      activeNamesCollapseRequest: '1'
    }
  },
  created () {
    this.getReportInfo()
  },
  mounted () {
    this.queryReportInfo()
  },
  methods: {
    getReportInfo () {
      console.log(this.$store.state.reportInfo, '<-- 报告文字信息')
      this.reportInfoForm.report_name = this.$store.state.reportInfo.report_name
      this.reportInfoForm.com_from = this.$store.state.reportInfo.com_from
      this.reportInfoForm.test_count = this.$store.state.reportInfo.test_count
      this.reportInfoForm.pass_count = this.$store.state.reportInfo.pass_count
      this.reportInfoForm.failed_count = this.$store.state.reportInfo.failed_count
      this.reportInfoForm.skip_count = this.$store.state.reportInfo.skip_count || 0
      this.reportInfoForm.pass_rate = this.$store.state.reportInfo.pass_rate
      this.reportInfoForm.created_time = this.$store.state.reportInfo.created_time
      this.reportInfoForm.total_time = this.$store.state.reportInfo.total_time
      this.reportInfoForm.conclusion = this.$store.state.reportInfo.conclusion
    },
    queryReportInfo () {
      const chart = this.$refs.caseRunInfo
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        console.log('出具报告')
        const myChart = echarts.init(chart)
        console.log(reportInfoOption, '<-- Echarts报告数据')
        const pieData = [
          { name: '通过', value: this.reportInfoForm.pass_count },
          { name: '失败', value: this.reportInfoForm.failed_count },
          { name: '跳过', value: this.reportInfoForm.skip_count }
        ]
        console.log(pieData, '<--修改的饼图数据')
        reportInfoOption.title.text = '执行情况分布图'
        reportInfoOption.title.subtext = this.$store.state.reportInfo.com_from
        reportInfoOption.series[0].data = pieData
        console.log(reportInfoOption, '<-- Echarts报告数据2')
        const option = reportInfoOption
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }

      // 将case_data 进行排序，依据执行时长从大到小
      const caseData = this.tableData.slice()
      caseData.sort((a, b) => {
        // 将 over_time 属性从字符串转换为数字进行比较
        const timeA = parseFloat(a.over_time);
        const timeB = parseFloat(b.over_time);

        // 降序排序，如果需要降序，可以将 a 和 b 互换位置
        return timeB - timeA;
      })
      const caseNameList = caseData.map(caseInfo => caseInfo.case_name)
      const caseOverTime = caseData.map(caseInfo => caseInfo.over_time)
      console.log(caseNameList, '<-- 组装的casename数据2')
      console.log(caseOverTime, '<-- 组装的over_time数据2')
      // const minValue = Math.min(...caseOverTime)
      const maxValue = (Math.max(...caseOverTime) + 1).toFixed(0)
      const intervalValue = maxValue / 10

      const chartCaseTimeTop = this.$refs.caseTimeTop
      if (chartCaseTimeTop) {
        const myChartCaseTimeTop = echarts.init(chartCaseTimeTop)
        const optionCaseTimeTop = {
          title: {
            text: '用例执行耗时Top',
            // subtext: 'Fake Data',
            left: 'center'
          },
          // 悬浮展示效果
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          color: '#ea7ccc',
          xAxis: {
            type: 'category',
            data: caseNameList
          },
          yAxis: {
            type: 'value',
            name: '耗时',
            min: 0,
            max: maxValue,
            interval: intervalValue,
            axisLabel: {
              formatter: '{value} ms'
            }
          },
          series: [
            {
              data: caseOverTime,
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' ms';
                }
              }
            }
          ]
        }
        myChartCaseTimeTop.setOption(optionCaseTimeTop)
        window.addEventListener('resize', function () {
          myChartCaseTimeTop.resize()
        })
      }
    },
    queryCaseRequestInfo (rowData) {
      // 初始化抽屉内容
      this.caseRequestInfo = rowData
      this.caseRequestInfo.case_var.pre_request_data = rowData.case_var.pre_request_data
      console.log(this.caseRequestInfo)
      this.drawerCaseRequestInfo = true
    }
  }
}
</script>

<style lang="scss" scoped>
.reportinfo-main {
  display: flex;
  justify-content: space-between;
}
.box-card-info {
  width: 50%;
  height: 450px;
  margin-bottom: 5px;
}
.box-card-1 {
  position: relative;
}
.box-card-2 {
  width: 50%;
  height: 450px;
  margin-left: 5px;
}
.reportinfo-card-1-form {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
}
</style>
